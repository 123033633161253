<template>
  <div>
    <CustomerDetail></CustomerDetail>
  </div>
</template>

<script>
import CustomerDetail from "@/components/admin/customerDetail";
export default {
  components: { CustomerDetail },
};
</script>

<style>
</style>