<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="136.707"
    height="136.707"
    viewBox="0 0 136.707 136.707"
  >
    <g
      id="Group_104"
      data-name="Group 104"
      transform="translate(-327.582 -180.369)"
    >
      <g id="Group_101" data-name="Group 101">
        <path
          id="Path_3397"
          data-name="Path 3397"
          d="M374.7,194.7a.935.935,0,0,0-.338.064.916.916,0,0,0-.511,1.192l1.819,4.548.5-.2c.327-.129.654-.257.986-.377l.23-.083-1.829-4.574A.918.918,0,0,0,374.7,194.7Z"
          fill="#281930"
        />
        <path
          id="Path_3398"
          data-name="Path 3398"
          d="M358.07,212.084l.167-.165c.214-.212.435-.419.654-.625l.433-.409-10.495-10.5a.867.867,0,1,0-1.227,1.225Z"
          fill="#281930"
        />
        <path
          id="Path_3399"
          data-name="Path 3399"
          d="M463.421,247.855h-16.8s.018.756.018.867-.018.868-.018.868h16.8a.868.868,0,1,0,0-1.735Z"
          fill="#281930"
        />
        <path
          id="Path_3400"
          data-name="Path 3400"
          d="M342.912,248.722c0-.11.018-.867.018-.867H328.45a.868.868,0,1,0,0,1.735h14.48S342.912,248.833,342.912,248.722Z"
          fill="#281930"
        />
        <path
          id="Path_3401"
          data-name="Path 3401"
          d="M395.935,180.369a.869.869,0,0,0-.868.867v15.632h.233c.421.005.84.019,1.258.032l.243.008V181.235A.866.866,0,0,0,395.935,180.369Z"
          fill="#281930"
        />
        <path
          id="Path_3402"
          data-name="Path 3402"
          d="M418.835,195.314a.92.92,0,0,0-1.2.483l-2.278,5.313.213.094c.415.182.827.367,1.236.559l.221.1,2.293-5.348A.921.921,0,0,0,418.835,195.314Z"
          fill="#281930"
        />
        <path
          id="Path_3403"
          data-name="Path 3403"
          d="M442.844,229.235c.169.419.338.839.5,1.262l.085.225,5.955-2.38a.917.917,0,0,0-.341-1.768.94.94,0,0,0-.339.064l-5.945,2.377Z"
          fill="#281930"
        />
        <path
          id="Path_3404"
          data-name="Path 3404"
          d="M432.243,212.875l.372.394,11.653-11.653a.867.867,0,0,0-1.227-1.226l-11.625,11.626.165.167C431.806,212.409,432.024,212.643,432.243,212.875Z"
          fill="#281930"
        />
        <path
          id="Path_3405"
          data-name="Path 3405"
          d="M343.009,227.027l3.907,1.676.093-.217c.128-.3.265-.6.4-.894l.258-.563-3.937-1.688a.9.9,0,0,0-.36-.074.915.915,0,0,0-.843.555.918.918,0,0,0,.48,1.205Z"
          fill="#281930"
        />
        <path
          id="Path_3406"
          data-name="Path 3406"
          d="M432.615,284.176l-.418.441c-.2.217-.407.434-.616.644l-.166.166,11.628,11.628a.867.867,0,1,0,1.226-1.225Z"
          fill="#281930"
        />
        <path
          id="Path_3407"
          data-name="Path 3407"
          d="M448.861,270.418l-5.89-2.526-.09.223c-.17.42-.341.837-.521,1.251l-.094.218,5.874,2.519a.893.893,0,0,0,.359.075.918.918,0,0,0,.843-.556.918.918,0,0,0-.481-1.2Z"
          fill="#281930"
        />
        <path
          id="Path_3408"
          data-name="Path 3408"
          d="M415.868,296.107l-.224.1c-.414.183-.829.358-1.248.532l-.216.088,2.135,5.342a.917.917,0,1,0,1.7-.679Z"
          fill="#281930"
        />
        <path
          id="Path_3409"
          data-name="Path 3409"
          d="M374.685,296.549l-.215-.09-1.914,4.466a.916.916,0,0,0,.482,1.2.934.934,0,0,0,.36.073.915.915,0,0,0,.843-.554l1.934-4.512-.577-.224C375.291,296.794,374.986,296.676,374.685,296.549Z"
          fill="#281930"
        />
        <path
          id="Path_3410"
          data-name="Path 3410"
          d="M358.237,285.524l-.167-.164L347.6,295.83a.867.867,0,0,0,1.227,1.225l10.5-10.494-.352-.334C358.725,285.995,358.478,285.764,358.237,285.524Z"
          fill="#281930"
        />
        <path
          id="Path_3411"
          data-name="Path 3411"
          d="M395.3,300.573l-.234,0v15.632a.868.868,0,1,0,1.735,0V300.534l-.419.014C396.024,300.559,395.663,300.571,395.3,300.573Z"
          fill="#281930"
        />
        <path
          id="Path_3412"
          data-name="Path 3412"
          d="M346.671,268.11l-.231-.587-3.95,1.577a.921.921,0,0,0-.512,1.192.911.911,0,0,0,.851.578.878.878,0,0,0,.341-.066l3.952-1.579-.094-.223C346.9,268.709,346.788,268.411,346.671,268.11Z"
          fill="#281930"
        />
      </g>
      <path
        id="Path_3413"
        data-name="Path 3413"
        d="M362.021,260.716a2.042,2.042,0,0,1-.284.14c-.584.189-4.937,1.069-5.376,1.209s-18.49-8.2-18.49-8.2c5.8-4.919,12.682-6.11,18.945-5.738A31.3,31.3,0,0,0,362.021,260.716Zm71.6-13.112a31.038,31.038,0,0,1-4.316,14.182c1.17.25,2.251.483,2.462.552.465.145,19.661-8.505,19.661-8.505A27.182,27.182,0,0,0,433.625,247.6Z"
        fill="#ff2457"
      />
      <path
        id="Path_3414"
        data-name="Path 3414"
        d="M443.624,248.722a48.847,48.847,0,1,1-48.846-48.846A48.844,48.844,0,0,1,443.624,248.722Z"
        fill="#0c8abc"
      />
      <path
        id="Path_3415"
        data-name="Path 3415"
        d="M436.323,248.722a41.546,41.546,0,1,1-41.546-41.545A41.544,41.544,0,0,1,436.323,248.722Z"
        fill="#281930"
      />
      <text
        :id="`_${year}`"
        :data-name="year"
        transform="translate(360.436 249.565)"
        fill="#fff"
        font-size="32"
        font-family="Helvetica"
      >
        <tspan x="0" y="0">{{ year }}</tspan>
      </text>
      <g id="Group_103" data-name="Group 103">
        <path
          id="Path_3416"
          data-name="Path 3416"
          d="M451.41,253.86l.021,30.008s-18.771,6.449-56.78,6.449-56.759-6.449-56.759-6.449l-.022-30.008s17.661,6.447,56.781,6.447S451.41,253.86,451.41,253.86Z"
          fill="#281930"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    year: {
      default: 2022,
    },
  },
};
</script>

<style>
</style>