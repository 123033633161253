<template>
  <div>
    <div class="mb-4">
      <router-link :to="`/admin/kunden`">
        <button class="blue-button">
          <font-awesome-icon :icon="faChevronLeft" />
          {{ $t("back") }}
        </button>
      </router-link>
    </div>
    <div class="mb-2">
      <UserInfoCard></UserInfoCard>
    </div>
    <div class="row align-items-center">
      <h3 class="d-flex align-items-center col-12 col-lg-6">
        <span class="mr-3"
          ><year-icon width="50" height="50" :year="$route.params.year">
          </year-icon
        ></span>
        {{ $t("documentsOfYear", { year: $route.params.year }) }}
        <span class="text-muted ml-2">{{ documents.length }}</span>
      </h3>
      <div class="col-12 col-lg-6 d-flex justify-content-end">
        <button class="blue-button" @click="showNoteModal = true">
          {{ $t("notes") }}
        </button>
      </div>
      <b-modal
        id="year-note-modal"
        centered
        :title="$t('addNote')"
        :no-close-on-backdrop="true"
        v-model="showNoteModal"
        hide-footer
        size="lg"
      >
        <AddNote @scrollView="scrollView" />
      </b-modal>
    </div>
    <p>{{ $t("documentsDescription") }}</p>
    <div>
      <h6 class="text-blue">{{ $t("companyEmployed") }}:</h6>
      <div v-if="userCompany">
        <div class="mt-2">
          {{ $t("companyName") }}: {{ userCompany.companyName }}
          {{ $t("address") }}: {{ userCompany.street }}, {{ $t("number") }}
          {{ userCompany.no }},
          {{ userCompany.city }}
          {{ userCompany.postalCode }}
          <strong class="text-primary" v-if="userCompany.homeOfficeDayCount > 0"> {{$t('homeOfficeDescription', { count: userCompany.homeOfficeDayCount })}}  </strong>
        </div>
      </div>
      <div v-else>
        <p>{{ $t("notFoundCompany") }}</p>
      </div>
    </div>
    <div>
      <h6 class="text-blue mt-2">{{ $t("paymentPartner") }}:</h6>
      <div>
        <div class="mt-2">
          {{
            isPartnerPayment.isPartnerPayment ? $t("assess") : $t("assessment")
          }}
        </div>
      </div>
    </div>
    <div>
      <h6 class="text-blue mt-2">{{ $t("childInformation") }}:</h6>
      <div v-if="childrens?.length > 0">
        <div
          v-for="(item, index) in childrens"
          :key="index"
          class="d-flex align-items-center"
        >
          <p class="mr-2">
            <span class="text-dark-blue font-weight-bold"
              >{{ $t("nameSurname") }}:</span
            >
            {{ item.name }} {{ item.surname }}
          </p>
          <p>
            <span class="text-dark-blue font-weight-bold"
              >{{ $t("birthDate") }}:</span
            >
            {{ new Date(item.birthDate).toLocaleDateString() }}
          </p>
        </div>
      </div>
      <div v-else>
        <p>{{ $t("notFoundChild") }}</p>
      </div>
    </div>
    <div class="col-12 my-5 text-center" v-if="isLoading">
      <b-spinner
        variant="primary"
        style="width: 2rem; height: 2rem"
      ></b-spinner>
    </div>
    <div v-else class="c-customer-detail row mt-4">
      <div
        class="c-customer-detail__item"
        :class="{
          'c-customer-detail__item__variant--disabled':
            document.file.length === 0,
        }"
        v-for="(document, i) in documents"
        @click="
          $router.push(
            `/admin/kunden/${$route.params.id}/${$route.params.year}/${document.category}`
          )
        "
        :key="i"
      >
        <img
          src="@/assets/img/icon/document.png"
          class="c-customer-detail__item__icon"
          alt=""
        />
        <div class="mt-4 text-center">
          <h6>{{ $t(setCategoryName(document.category)) }}</h6>
          <div>
            <small class="text-muted"
              >({{ $t("foundFile", { length: document.file.length }) }})</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import UserInfoCard from "../userInfo/userInfoCard.vue";
import {
  faArrowUpFromBracket,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { getFiles } from "../../../services/modules/File";
import YearIcon from "../customerYears/yearIcon.vue";
import { getUserInfo } from "../../../services/modules/User";
import AddNote from "../note/AddNote.vue";

export default {
  components: { FontAwesomeIcon, UserInfoCard, YearIcon, AddNote },
  data() {
    return {
      faArrowUpFromBracket,
      user: {},
      faChevronLeft,
      isLoading: true,
      userCompany: "",
      isPartnerPayment: false,
      childrens: [],
      documents: [
        { title: "incomeTaxCertificate", files: [] },
        { title: "investmentIncome", files: [] },
        { title: "advertisingCosts", files: [] },
        { title: "movingCosts", files: [] },
        { title: "insurances", files: [] },
        { title: "donations", files: [] },
        { title: "contributions", files: [] },
        { title: "other", files: [] },
        { title: "notice", files: [] },
        { title: "calculationList", files: [] },
        { title: "revenuesFromAbroad", files: [] },
        { title: "childExpenses", files: [] },
        { title: "taxDeclarations", files: [] },
      ],
      showNoteModal: false,
    };
  },
  methods: {
    setCategoryName(category) {
      const firstLetter = category.charAt(0);
      const others = category.slice(1);
      return firstLetter.toLowerCase() + others;
    },
    async getFilesAll() {
      this.isLoading = true;
      const response = await getFiles(
        this.$route.params.id,
        this.$route.params.year
      );
      if (response.message === "OK") this.documents = response.data.items;
      this.isLoading = false;
    },
    async getUser() {
      const response = await getUserInfo(
        this.$route.params.id,
        this.$route.params.year
      );
      if (response.message === "OK") {
        this.user = response.data;
        this.userCompany = response.data.userCompanies.find(
          (item) => item.taxYear == Number(this.$route.params.year)
        );
        this.isPartnerPayment = response.data.userPartnerPayments.find(
          (item) => item.taxYear == Number(this.$route.params.year)
        );
        response.data.userChilds.map((item) => {
          if (item.taxYear == Number(this.$route.params.year)) {
            this.childrens.push(item);
          }
        });
      } else this.user = {};
    },
    scrollView() {
      const id = document.getElementById("year-note-modal");
      id.scrollIntoView(true);
    },
  },
  mounted() {
    this.getFilesAll();
    this.getUser();
  },
};
</script>

<style lang=scss>
#year-note-modal {
  .modal-content {
    height: 600px;
    overflow-y: scroll;
  }
}
</style>