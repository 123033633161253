<template>
  <div>
    <Alert :alert="alert" v-if="alert.show" />
    <b-form-group>
      <label for="note">Not</label>
      <b-form-textarea
        rows="4"
        id="note"
        :placeholder="$t('enterNote')"
        v-model="plainText"
      />
    </b-form-group>
    <div class="d-flex w-100">
      <button class="blue-button w-100 mr-3" type="submit" @click="addNote">
        <span v-if="!actionLoading"
          >{{ selectedNote != null ? $t("updateNote") : $t("addNote") }}
        </span>
        <b-spinner variant="light" class="font-size-14" v-else></b-spinner>
      </button>
      <button
        class="blue-button"
        v-if="selectedNote != null"
        @click="resetNote"
      >
        Vazgeç
      </button>
    </div>
    <div class="notes mt-4">
      <h5>Notlar</h5>
      <div v-if="loading" class="text-center mt-2">
        <b-spinner small style="width: 2rem; height: 2rem"></b-spinner>
      </div>
      <div class="text-center my-4" v-else-if="notes.length == 0">
        <img
          width="80"
          src="../../../assets/images/no-found.png"
          :alt="$t('noFoundData')"
        />
        <p>
          <small>{{ $t("noFoundData") }}</small>
        </p>
      </div>
      <div v-else>
        <div v-for="(item, index) in notes" :key="item.id" class="note-card">
          <div class="d-flex justify-content-between note-card-content">
            <div>
              <strong>{{ item.adminName }}</strong>
              <p>{{ item.plainText }}</p>
            </div>
            <div class="d-flex">
              <font-awesome-icon
                :icon="faPencil"
                class="text-primary mr-2"
                @click="() => editNote(item)"
              />
              <font-awesome-icon
                :icon="faTrash"
                @click="() => deleteNote(item.id, index)"
                class="text-danger"
              />
            </div>
          </div>
          <p class="note-card-date">
            <span>Not Tarihi: </span>{{ setDate(item.createdDate) }}
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <b-pagination
            @change="changePage"
            v-model="page"
            :total-rows="count"
            :per-page="limit"
            ul-class="c-pagination"
            li-class="c-pagination__list"
            active-class="c-pagination__list--active"
            aClass="c-pagination__list__action"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  addTaxYearNote,
  deleteTaxYearNote,
  getTaxYearNoteList,
  updateTaxYearNote,
} from "../../../services/modules/User";
import Alert from "../../base/alert/Alert.vue";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    Alert,
    FontAwesomeIcon,
  },
  data() {
    return {
      plainText: "",
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      actionLoading: false,
      notes: [],
      page: 1,
      limit: 5,
      count: 0,
      faTrash,
      faPencil,
      selectedNote: null,
      loading: false,
    };
  },
  methods: {
    async addNote() {
      if (this.plainText !== "") {
        this.actionLoading = true;
        const response =
          this.selectedNote != null
            ? await updateTaxYearNote(this.selectedNote.id, this.plainText)
            : await addTaxYearNote(
                this.plainText,
                Number(this.$route.params.id),
                Number(this.$route.params.year)
              );

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "İşlem Başarılı!";
          this.noteList();
          this.plainText = "";
          this.selectedNote = null;
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "İşlem Başarısız!";
        }

        this.actionLoading = false;
        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    async noteList() {
      this.loading = true;
      const response = await getTaxYearNoteList(
        this.$route.params.id,
        this.$route.params.year,
        this.page,
        this.limit
      );

      if (response.message === "OK") {
        this.notes = response.data.items;
        this.count = response.data.count;
      }

      this.loading = false;
    },
    changePage(page) {
      this.page = page;
      this.noteList();
    },
    async editNote(note) {
      this.$emit("scrollView");
      this.plainText = note.plainText;
      this.selectedNote = note;
    },
    resetNote() {
      (this.plainText = ""), (this.selectedNote = null);
    },
    async deleteNote(noteId, index) {
      const response = await deleteTaxYearNote(noteId);

      if (response.message === "OK") {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "İşlem Başarılı!";
        this.notes.splice(index, 1);
      } else {
        this.alert.show = true;
        this.alert.variant = "danger";
        this.alert.message = "İşlem Başarısız!";
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
  },
  mounted() {
    this.noteList();
  },
};
</script>

<style lang="scss">
.notes {
  .note-card {
    background-color: #f5f8fb;
    // padding: 10px 20px;
    border-radius: 12px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .note-card-content {
      border-bottom: 1px solid #dde9f5;
      padding: 10px 20px;
    }

    .note-card-date {
      color: #9a9ab0;
      padding: 10px 20px;
      font-size: 14px;

      span {
        color: #57c6ff;
      }
    }
  }
}
</style>